import React from 'react'

const Steps2 = ({elem}) => {
  return (
    <div className='steps-2'>
        <div className="main-container">
            <div className="row">
                <div className="col-12">
                    <h3 className='h3_1dd7_10'>{elem.title}</h3>
                </div>
                {elem.elements?.map((card, index) =>
                    <div className="col-md-6 col-lg-4 steps-item" key={index}>
                        <h4>{card.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: card.description }}></div>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Steps2