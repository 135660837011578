import React from 'react'
import { NavLink } from 'react-router-dom'

const Advantages = ({elem}) => {
  return (
    <div className="gdlr-core-wrapper-1">
        <div className="advantages">
            <div className="row justify-content-center">
                {elem.elements?.map((card, index) =>
                    <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                        <NavLink to={card.link} className="d-flex align-items-center advantages-item">
                            <img src={ card.image} alt="" />
                            <div>
                                <h4>{card.title}</h4>
                                <p>{card.subtitle}</p>
                            </div>
                        </NavLink>
                    </div>
                )}
            </div> 
        </div>
    </div>
  )
}

export default Advantages