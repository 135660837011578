import React from 'react'
import { Modal } from 'react-bootstrap';

const WorkerModal = ({show, setShow, item}) => {
    const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <div className='row worker-item'>
                <div className="col-lg-6 col-md-12">
                    <img src={ item.image} alt="" />
                </div>
                <div className="col-lg-6 col-md-12">
                    <h4>{item.name}</h4>
                    <p className='position'>{item.position}</p>
                    <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default WorkerModal