import React from 'react'
import ShareBlock from './ShareBlock'
import Gallery from './Gallery'

const ArticleContainer = ({item}) => {
  return (
    <div className='article-container'>
        <div className="main-container">
            <div dangerouslySetInnerHTML={{ __html: item?.description }}>
            </div>
            {item &&
                item.elements?.map((elem, index) =>
                    <div key={index}>
                        {
                            elem.type === "gallery" ?
                                <Gallery elem={elem}/>
                            :
                            <></>
                        }
                    </div>    
                )
            }
            <ShareBlock/>
        </div>
    </div>
  )
}

export default ArticleContainer