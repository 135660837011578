import React from 'react'
import PartnersServices from '../../services/general/partnersServices'
import useFetchItems from '../../hooks/useFetchItems'

const Partners = ({elem}) => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(PartnersServices.index)

  return (
    <div className='partners'>
        <div className="main-container">
            <div className="row justify-content-center">
                {items?.items?.map((card, index) =>
                    <div className="col-lg-2 col-4" key={index}>
                        <div>
                            <img src={ card.image} alt="" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Partners