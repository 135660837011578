import React from 'react'
import { useState } from 'react';
import { Dropdown, Offcanvas } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import MenuItem from '../simple/MenuItem';
import { useLangContext } from '../../i18n/ProvideLang';

const MobileHeader = ({logo, items}) => {
    const [show, setShow] = useState(false);
    const {lang} = useLangContext();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <div className="kingster-mobile-header-wrap">
        <div className="kingster-mobile-header kingster-header-background kingster-style-slide kingster-sticky-mobile-navigation " id="kingster-mobile-header">
            <div className="kingster-mobile-header-container kingster-container clearfix">
                <div className="kingster-logo  kingster-item-pdlr">
                    <div className="kingster-logo-inner">
                        <NavLink to={`/${lang}`}><img src={ logo} alt="" /></NavLink>
                    </div>
                </div>
                <div className="kingster-mobile-menu-right">
                    <div className="kingster-mobile-menu">
                        <div className={show ? "kingster-mm-menu-button kingster-mobile-menu-button kingster-mobile-button-hamburger kingster-active" : "kingster-mm-menu-button kingster-mobile-menu-button kingster-mobile-button-hamburger"} onClick={handleShow}><span></span></div>
                        <Offcanvas show={show} onHide={handleClose}>
                            <Offcanvas.Header>
                            <div onClick={handleClose}><span className='close'></span></div>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <ul className='main-ul'>
                                    {items?.map((elem, index) =>
                                        <MenuItem elem={elem} key={index} setShow={setShow}/>
                                    )}
                                </ul>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MobileHeader