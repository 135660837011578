import React from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import AppServices from '../../services/general/appServices';
import { useState } from 'react';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import { useEffect } from 'react';
import Utils from '../../services/utils';

const ApplicationModal = ({show, setShow}) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const {localizationsItems} = useLangContext();
    const [points, setPoints] = useState();
    const [scText, setScText] = useState();

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {
        items,
    } = useFetchItems(AppServices.settings)

    useEffect(() => {
        if(items){
            let values = items.find(it => it.type === "form-modal-inputs")?.value;
            let text = items.find(it => it.type === "b24-form-script")?.value;
            setScText(text)
            setPoints(values);
            if(!values && !text){
                setPoints([localizationsItems?.name, localizationsItems?.phone])
            }
        }
    }, [items])
    
    const handleClose = () => setShow(false);

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {
                ...data
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if (res.statusCode === 200) {
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                handleClose();
            }, 3000);
        }

        setLoading(false);
    };

    useEffect(() => {
        if(scText){
            const bitForm = document.getElementById('bitformmodal');
            
            if(!bitForm.hasChildNodes()){
                const divFragment =  document.createRange().createContextualFragment(scText[0])
            
                bitForm.appendChild(divFragment)
            }
        }
    }, [scText])

  return (
    <Modal show={show} onHide={handleClose} centered  className='modal-max-width'>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="application-modal">
            <div>
                <div className='modal-top'>
                    <h3>{localizationsItems?.modal_title}</h3>
                    <p>{localizationsItems?.modal_content}</p>
                </div>
                {scText ?
                    <div id='bitformmodal'></div>
                    :
                    <form onSubmit={handleSubmit(CustomSubmit)}>
                        {points &&
                            <div className='modal-flex'>
                                {points.map((point, index) =>
                                    <label key={index}>
                                        <input {...register(point)} type="text" className="input" name={point} placeholder={point}/>
                                    </label>
                                )}
                                <div className='flex'>
                                    <button type='submit' className='my-button'>{localizationsItems?.modal_button}</button>
                                </div>
                            </div>
                        }
                    </form>
                }
            </div>
            <div className={visible ? 'modal-success modal-success-visible' : 'modal-success'}>
                <h3>{localizationsItems?.form_thanks}</h3>
                <p>{localizationsItems?.form_content}</p>
            </div>
            </Modal.Body>
    </Modal>
  )
}

export default ApplicationModal