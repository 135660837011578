import React from 'react'
import { NavLink } from 'react-router-dom'

const Steps = ({elem}) => {
  return (
    <div className='steps'>
        <div className="main-container">
            <div className="steps-wrapper">
                {elem.elements?.map((card, index) =>
                    <NavLink to={card.link} className="steps-item" key={index}>
                        <div className='step-card'>
                            {card.image && <img src={ card.image} alt="" />}
                            <div className='step-content'>
                                <h4>{card.title}</h4>
                                <div dangerouslySetInnerHTML={{ __html: card.description }}></div>
                            </div>
                        </div>
                    </NavLink>
                )}
            </div>
        </div>
    </div>
  )
}

export default Steps