import React from 'react'

const PageTop = ({item}) => {
  return (
    <div className='page-top'>
        <div className='back-img'>
            <img src={ item?.detail_image} alt="" />
        </div>
        <div className="main-container">
            <div className='page-top-content'>
                <div className='subtitle'>{item?.subtitle}</div>
                <h1>{item?.title}</h1>
            </div>
        </div>
    </div>
  )
}

export default PageTop