import ArticleDetail from "../pages/blog/ArticleDetail";
import Blog from "../pages/blog/Blog";
import Error from "../pages/error/Error";
import EventDetail from "../pages/events/EventDetail";
import EventsPage from "../pages/events/EventsPage";
import MainPage from "../pages/main/MainPage";
import SamplePage from "../pages/sample/SamplePage";
import { BLOG_DETAIL_PAGE_ROUTE, BLOG_PAGE_ROUTE, ERROR_PAGE_ROUTE, EVENTS_PAGE_ROUTE, EVENT_DETAIL_PAGE_ROUTE, MAIN_PAGE_ROUTE, SAMPLE_PAGE_SOUTE } from "./Constants";

export const publicRoutes = [
    {
        path: '/',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: BLOG_PAGE_ROUTE,
        Component: Blog
    },
    {
        path: SAMPLE_PAGE_SOUTE,
        Component: SamplePage
    },
    {
        path: BLOG_DETAIL_PAGE_ROUTE,
        Component: ArticleDetail
    },
    {
        path: EVENTS_PAGE_ROUTE,
        Component: EventsPage
    },
    {
        path: EVENT_DETAIL_PAGE_ROUTE,
        Component: EventDetail
    },
    {
        path: ERROR_PAGE_ROUTE,
        Component: Error
    }
]