import React from 'react'
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    InstapaperShareButton,
    WhatsappShareButton
  } from "react-share";
import { useLangContext } from '../../i18n/ProvideLang';

const ShareBlock = () => {
    const shareUrl = window.location.href;
    const {localizationsItems} = useLangContext();

  return (
    <div className="share-box">
        <div className="tag-area">
            <span>{localizationsItems?.share}</span>
        </div>
        <div className="social-icons">
            <ul>
                <li>
                    <FacebookShareButton url={shareUrl}>
                        <i className="fa fa-facebook"></i>
                    </FacebookShareButton>
                </li>
                <li>
                    <WhatsappShareButton url={shareUrl}>
                        <i className="fa fa-whatsapp"></i>
                    </WhatsappShareButton>
                </li>
                <li>
                    <TwitterShareButton url={shareUrl}>
                        <i className="fa fa-twitter"></i>
                    </TwitterShareButton>
                </li>
                <li>
                    <InstapaperShareButton url={shareUrl}>
                        <i className="fa fa-instagram"></i>
                    </InstapaperShareButton>
                </li>
                <li>
                    <TelegramShareButton url={shareUrl}>
                        <i className="fa fa-telegram"></i>
                    </TelegramShareButton>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default ShareBlock