import React from 'react'

const TextComponent = ({elem}) => {
  return (
    <div className='main-container'>
        {elem.title && <h3 className='main-title'>{elem.title}</h3>}
        <div className='text-component' dangerouslySetInnerHTML={{ __html: elem.description}}></div>
    </div>
  )
}

export default TextComponent