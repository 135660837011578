import React, { useState } from 'react'
import WorkerModal from '../modals/WorkerModal';

const WorkerItem = ({slide}) => {
    const [show, setShow] = useState(false);

    const openModal = () => {
        if(slide.description){
            setShow(true)
        }
    }
  return (
    <>
        <div className='worker-slide' onClick={openModal}>
            <img src={ slide.image} alt="" />
            <div>
                <h5>{slide.name}</h5>
                <p>{slide.position}</p>
            </div>
        </div>
        {show && <WorkerModal show={show} setShow={setShow} item={slide}/>}
    </>
  )
}

export default WorkerItem