import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import img1 from "../../upload/icon-envelope.png"
import EventsServices from '../../services/general/eventsServices';
import useFetchItems from '../../hooks/useFetchItems';
import Utils from '../../services/utils';
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';
import { useForm } from 'react-hook-form';
import AppServices from '../../services/general/appServices';

const EventsBlock = ({elem}) => {
    const [leftBlock, setLeftBlock] = useState();
    const [rightBlock, setRightBlock] = useState();
    const {lang, localizationsItems} = useLangContext();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [points, setPoints] = useState();
    const [scText, setScText] = useState();

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const {
        items,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(EventsServices.index)

    const {
        items:settings,
    } = useFetchItems(AppServices.settings)

    useEffect(() => {
        if(elem){
            setLeftBlock(elem.elements?.find(it => it.type === "left_block"))
            setRightBlock(elem.elements?.find(it => it.type === "right_block"));
        }
    }, [elem])

    useEffect(() => {
        if(settings){
            let values = settings.find(it => it.type === "event-form-inputs")?.value;
            let text = settings.find(it => it.type === "b24-event-script")?.value;
            setScText(text)
            setPoints(values);
            if(!values && !text){
                setPoints([localizationsItems?.name, localizationsItems?.phone, localizationsItems?.email])
            }
        }
    }, [settings])

    const CustomSubmit = async (data) => {
        setLoading(true);

        const res = await AppServices.requestTemplate(data);

        if(res.statusCode === 200){
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
        }else{
            console.log(res)
        }
        setLoading(false)
    }

    useEffect(() => {
        if(scText){
            const bitForm = document.getElementById('biteventform');
            
            if(!bitForm.hasChildNodes()){
                const divFragment =  document.createRange().createContextualFragment(scText[0])
            
                bitForm.appendChild(divFragment)
            }
        }
    }, [scText])


  return (
    <div className='events-block'>
        <div className="main-container">
            <div className="row">
                {leftBlock &&
                <div className="col-lg-4 col-sm-12 col-4">
                    <div className='left-block'>
                        <img src={ leftBlock.image} alt="" />
                        {/*<h3>{leftBlock.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: leftBlock.description }}></div>*/}
                    </div>
                </div>}
                <div className="col-lg-4 col-sm-12 col-4 events-main">
                    <div className='events-wrapper'>
                        <h3>{elem.title}</h3>
                        {items?.items?.slice(0,4).map((ev, index) =>
                            <div key={index} className='event-card'>
                                <div className='date'>
                                    <span>{Utils.generateDate2(ev.date).day}</span>
                                    <p>{Utils.generateDate2(ev.date, lang).mon}</p>
                                </div>
                                <div>
                                    <NavLink to={`/${lang}/events/${ev.slug}`}><h5>{ev.title}</h5></NavLink>
                                </div>
                            </div>
                        )}
                        <NavLink to={`/${lang}/events`} className='link'>{localizationsItems?.all_events}</NavLink>
                    </div>
                </div>
                {rightBlock && 
                <div className="col-lg-4 col-sm-12 col-4">
                    <div className='right-block'>
                        <div className='back-img'>
                            <img src={ rightBlock.image} alt="" />
                        </div>
                        {scText ?
                            <div id='biteventform'></div>
                            :
                            <form className='right-block-content' onSubmit={handleSubmit(CustomSubmit)}>
                                {points &&
                                    <>
                                        <i className='icon icon_mail_alt'></i>
                                        <h3>{rightBlock.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: rightBlock.description }}></div>
                                        {points.map((point, index) =>
                                            <input {...register(point)} type="text" className="input" name={point} placeholder={point}/>
                                            
                                        )}
                                        <button className='my-button'>{localizationsItems?.subscribe}</button>
                                    </>
                                }
                            </form>
                        }
                        <div className={visible ? 'form-contact-success form-contact-success-visible' : 'form-contact-success'}>
                            <h3>{localizationsItems?.form_thanks}</h3>
                            <p>{localizationsItems?.form_content}</p>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
  )
}

export default EventsBlock