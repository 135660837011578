import React, { useEffect, useState } from 'react'
import DropdownLanguages from '../simple/DropdownLanguages';
import { NavLink } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import ApplicationModal from '../modals/ApplicationModal';
import { useLangContext } from '../../i18n/ProvideLang';

const TopBar = ({item}) => {
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const {localizationsItems} = useLangContext();
    const [show, setShow] = useState(false)

    const { 
        items,
    } = useFetchItems(AppServices.menu, "top-menu");

    useEffect(() => {
        if(item){
            setEmail(item.find(it => it.type === "email")?.value)
            setPhone(item.find(it => it.type === "phone")?.value)
        }
    }, [item])


  return (
    <div className="kingster-top-bar">
        <div className="kingster-top-bar-background"></div>
        <div className="kingster-top-bar-container kingster-container ">
            <div className="kingster-top-bar-container-inner clearfix">
                <div className="kingster-top-bar-left kingster-item-pdlr">
                    {email?.map((em, index) =>
                        <span key={index}>
                            <i className="fa fa-envelope-open-o" id="i_fd84_1"></i> 
                            <a href={`mailto:${em}`} key={index}>{em}</a>
                        </span>
                    )}
                    {phone?.map((ph, index) =>
                        <span key={index}>
                            <i className="fa fa-phone" id="i_fd84_1"></i>
                            <span
                                dangerouslySetInnerHTML={{__html: ph}}
                            ></span>
                        </span>
                    )}
                </div>
                <div className="kingster-top-bar-right kingster-item-pdlr">
                    <ul id="kingster-top-bar-menu" className="kingster-top-bar-menu kingster-top-bar-right-menu">
                        {items?.map((el, index) =>
                            <li className="menu-item kingster-normal-menu" key={index}>
                                <NavLink to={el.value} target={el.type}>{el.title}</NavLink>
                            </li>
                        )}
                    </ul>
                    <div className="kingster-top-bar-right-social"></div>
                    <div className="kingster-top-bar-right-button" onClick={() => setShow(true)}>{localizationsItems?.top_button_text}</div>
                    <DropdownLanguages/>
                </div>
            </div>
        </div>
        {show && <ApplicationModal show={show} setShow={setShow}/>}
    </div>
  )
}

export default TopBar