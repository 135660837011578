import React from 'react'
import Utils from '../../services/utils'
import { useLangContext } from '../../i18n/ProvideLang'

const ArticleTop = ({item}) => {
    const {lang} = useLangContext();

  return (
    <div className='article-top'>
        <div className='back-img'>
            <img src={ item?.detail_image} alt="" />
        </div>
        <div className="main-container">
            <div className='page-top-content'>
                <div className='page-title'>
                    <div className='date'>
                        <span>{Utils.generateDate2(item?.date, lang).day}</span>
                        <p>{Utils.generateDate2(item?.date, lang).mon}</p>
                    </div>
                    <h1>{item?.title}</h1>
                </div>
            </div>
        </div>
        <div className='back-linear'></div>
    </div>
  )
}

export default ArticleTop