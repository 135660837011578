import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import AppServices from '../services/general/appServices';
import { ERROR_PAGE_ROUTE, MAIN_PAGE_ROUTE } from './Constants';
import { publicRoutes } from './Routes';
import Header from '../components/complex/Header';
import Footer from '../components/complex/Footer';

const AppRouter = () => {
  const [items, setItems] = useState();

  const fetchdata = async () => {
    const res = await AppServices.settings();

    if(res.statusCode === 200){
      setItems(res.content)
    }
  }
  
  useEffect(() => {
    fetchdata();
  }, [])

  useEffect(() => {
    if(items){
      let color = items.find(it => it.type === "color-primary")?.value;
      let accentColor = items.find(it => it.type === "accent-color")?.value;
      let accentHoverColor = items.find(it => it.type === "accent-hover-color")?.value;
      let footerBottomColor = items.find(it => it.type === "footer-bottom-color")?.value;
      let footerColor = items.find(it => it.type === "footer-color")?.value;
      let headerTopColor = items.find(it => it.type === "header-top-color")?.value;
      let headerMenuColor = items.find(it => it.type === "header-menu-color")?.value;
      let menuFontColor = items.find(it => it.type === "menu-font-color")?.value;
      let footerIconColor = items.find(it => it.type === "footer-icon-color")?.value;
      let advantagesFontColor = items.find(it => it.type === "advantages-font-color")?.value;
      let headerFontColor = items.find(it => it.type === "header-font-color")?.value;
      let buttonColor = items.find(it => it.type === "button-color")?.value;
      let buttonFontColor = items.find(it => it.type === "button-font-color")?.value;
      let buttonHoverColor = items.find(it => it.type === "button-hover-color")?.value;
      let faqBackColor = items.find(it => it.type === "faq-back-color")?.value;
      let faqColor = items.find(it => it.type === "faq-color")?.value;
      let faqItemColor = items.find(it => it.type === "faq-item-color")?.value;
      let faqFontColor = items.find(it => it.type === "faq-font-color")?.value;
      let faqTitleColor = items.find(it => it.type === "faq-title-color")?.value;
      let faqTitleActive = items.find(it => it.type === "faq-title-active")?.value;
      let mainFontColor = items.find(it => it.type === "main-font-color")?.value;
      let advantages2TitleColor = items.find(it => it.type === "advantages2-title-color")?.value;
      let advantages2FontColor = items.find(it => it.type === "advantages2-font-color")?.value;
      let stepsBackColor = items.find(it => it.type === "steps-back-color")?.value;
      let stepsTitleColor = items.find(it => it.type === "steps-title-color")?.value;
      let stepsFontColor = items.find(it => it.type === "steps-cont-color")?.value;
      let darkColor = items.find(it => it.type === "dark-color")?.value;
      let partnersBackColor = items.find(it => it.type === "partners-back-color")?.value;
      let footerBackColor = items.find(it => it.type === "footer-back-color")?.value;
      let footerFontColor = items.find(it => it.type === "footer-font-color")?.value;
      let eventBlockColor = items.find(it => it.type === "event-block-color")?.value;
      let formColor = items.find(it => it.type === "form-color")?.value;
      let formFontColor = items.find(it => it.type === "form-forn-color")?.value;

      let mainFont = items.find(it => it.type === "font-primary")?.value;
      let secondatyFont = items.find(it => it.type === "font-secondary")?.value;

      let head = items.find(it => it.type === "head")?.value;
      let bottom = items.find(it => it.type === "bottom")?.value;
      let header = items.find(it => it.type === "header")?.value;

      if(head){
        head.forEach(element => {
            const divFragment =  document.createRange().createContextualFragment(element)
            document.head.append(divFragment);
        });
      }

      if(header){
        header.forEach(element => {
          const divFragment =  document.createRange().createContextualFragment(element)
          document.body.append(divFragment);
        });
      }

      if(bottom){
        bottom.forEach(element => {
          const divFragment =  document.createRange().createContextualFragment(element)
          document.body.prepend(divFragment);
        });
      }

      color && document.documentElement.style.setProperty('--mainColor', color);
      accentColor && document.documentElement.style.setProperty('--accentColor', accentColor);
      accentHoverColor && document.documentElement.style.setProperty('--accentHoverColor', accentHoverColor);
      footerBottomColor && document.documentElement.style.setProperty('--footerBottomColor', footerBottomColor);
      footerColor && document.documentElement.style.setProperty('--footerColor', footerColor);
      headerTopColor && document.documentElement.style.setProperty('--headerTopColor', headerTopColor);
      headerMenuColor && document.documentElement.style.setProperty('--headerMenuColor', headerMenuColor);
      menuFontColor && document.documentElement.style.setProperty('--menuFontColor', menuFontColor);
      footerIconColor && document.documentElement.style.setProperty('--footerIconColor', footerIconColor);
      advantagesFontColor && document.documentElement.style.setProperty('--advantagesFontColor', advantagesFontColor);
      headerFontColor && document.documentElement.style.setProperty('--headerFontColor', headerFontColor);
      buttonColor && document.documentElement.style.setProperty('--buttonColor', buttonColor);
      buttonFontColor && document.documentElement.style.setProperty('--buttonFontColor', buttonFontColor);
      buttonHoverColor && document.documentElement.style.setProperty('--buttonHoverColor', buttonHoverColor);
      faqBackColor && document.documentElement.style.setProperty('--faqBackColor', faqBackColor);
      faqColor && document.documentElement.style.setProperty('--faqColor', faqColor);
      faqItemColor && document.documentElement.style.setProperty('--faqItemColor', faqItemColor);
      faqFontColor && document.documentElement.style.setProperty('--faqFontColor', faqFontColor);
      faqTitleColor && document.documentElement.style.setProperty('--faqTitleColor', faqTitleColor);
      faqTitleActive && document.documentElement.style.setProperty('--faqTitleActive', faqTitleActive);
      mainFontColor && document.documentElement.style.setProperty('--mainFontColor', mainFontColor);
      advantages2TitleColor && document.documentElement.style.setProperty('--advantages2TitleColor', advantages2TitleColor);
      advantages2FontColor && document.documentElement.style.setProperty('--advantages2FontColor', advantages2FontColor);
      stepsBackColor && document.documentElement.style.setProperty('--stepsBackColor', stepsBackColor);
      stepsTitleColor && document.documentElement.style.setProperty('--stepsTitleColor', stepsTitleColor);
      stepsFontColor && document.documentElement.style.setProperty('--stepsFontColor', stepsFontColor);
      darkColor && document.documentElement.style.setProperty('--darkColor', darkColor);
      partnersBackColor && document.documentElement.style.setProperty('--partnersBackColor', partnersBackColor);
      footerBackColor && document.documentElement.style.setProperty('--footerBackColor', footerBackColor);
      footerFontColor && document.documentElement.style.setProperty('--footerFontColor', footerFontColor);
      eventBlockColor && document.documentElement.style.setProperty('--eventBlockColor', eventBlockColor);
      formColor && document.documentElement.style.setProperty('--formColor', formColor);
      formFontColor && document.documentElement.style.setProperty('--formFontColor', formFontColor);
      
      if(mainFont){
        let elements = mainFont.elements
        for(let i =0; i<elements.length; i++){
          const font_name = new FontFace(mainFont.font_name, `url(${elements[i].link})`, {
            style: elements[i].type,
            weight: elements[i].value
          });
          document.fonts.add(font_name);
        }
        document.documentElement.style.setProperty('--fontFamily', `${mainFont.font_name}, sans-serif`)
      }
      if(secondatyFont){
        let elements = secondatyFont.elements
        for(let i =0; i<elements.length; i++){
          const font_name = new FontFace(secondatyFont.font_name, `url(${elements[i].link})`, {
            style: elements[i].type,
            weight: elements[i].value
          });
          document.fonts.add(font_name);
        }
        document.documentElement.style.setProperty('--fontFamily2', `${secondatyFont.font_name}, sans-serif`)
      }
    }
  }, [items])

  return (
    <Routes>
        {publicRoutes.map(({path, Component}) => 
          <Route key={path} path={path} element={
            <>
                <Header item={items}/>
                <Component></Component>
                <Footer item={items}/>
            </>
          } exact/>
        )}
        <Route
            path="*"
            exact
            element={<Navigate to={ERROR_PAGE_ROUTE}/>}
        />

    </Routes>
  )
}

export default AppRouter