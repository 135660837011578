import React from 'react'
import ArticleContainer from '../../components/complex/ArticleContainer'
import Breadcrumbs from '../../components/simple/Breadcrumbs'
import ArticleTop from '../../components/complex/ArticleTop'
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import { useNavigate, useParams } from 'react-router-dom'
import EventsServices from '../../services/general/eventsServices'
import { useLangContext } from '../../i18n/ProvideLang'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'

const EventDetail = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/events/${slug}`)
            }
        }
    }, [lang])

    const {info, loading} = useFetchInfo(EventsServices.view, slug, null, lang);
  return (
    <>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
            :
        <div>
            {info &&
            <Helmet>
                <title>{info.seo_title ? info.seo_title : info.title}</title>
                {info.seo_description && <meta name="description" content={info.seo_description} />}
                {info.seo_keywords && <meta name="Keywords" content={info.seo_keywords}/>}
            </Helmet>}
            <ArticleTop item={info}/>
            <Breadcrumbs item={info}/>
            <ArticleContainer item={info}/>
        </div>
        }
    </>
  )
}

export default EventDetail