import React from 'react'
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const MenuItem = ({elem, setShow}) => {
    const [active, setActive] = useState(false);

  return (
    <li className="menu-item menu-item-has-children">
    <div className='flex'> 
        <NavLink to={elem.value} target={elem.type} onClick={() => setShow(false)}>{elem.title}</NavLink>                                           
        {elem.items && <span onClick={() => setActive(!active)}><i className={active ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i></span>}
    </div>
    {elem.items && 
        <ul className={active ? "sub-menu sub-menu-visible" : "sub-menu"}>
            {elem.items.map((el, index) =>
                <li className="menu-item menu-item-has-children" key={index}>
                    <NavLink to={el.value} target={el.type} onClick={() => setShow(false)}>{el.title}</NavLink>
                    {el.items &&
                        <ul className="sub-menu">
                            {el.items.map((it, index) =>
                                <li className="menu-item menu-item-has-children" key={index}>
                                    <NavLink to={it.value} target={it.type} onClick={() => setShow(false)}>{it.title}</NavLink>
                                    {it.items &&
                                        <ul className="sub-menu">
                                            {it.items.map((link, index) =>
                                                <li className="menu-item" key={index}>
                                                    <NavLink to={link.value} target={link.type} onClick={() => setShow(false)}>{link.title}</NavLink>
                                                </li>
                                            )}
                                        </ul>
                                    }
                                </li>
                            )}
                        </ul>
                    }
                </li>
            )}
        </ul>
    }
</li>
  )
}

export default MenuItem