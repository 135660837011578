import React from 'react'

const ThreeColumn = ({elem}) => {
  return (
    <div className='three-column'>
        <div className="main-container">
            <div className="row">
                <div className="col-lg-4 col-md-12">
                    <h3 className='main-title'>{elem.title}</h3>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div dangerouslySetInnerHTML={{ __html: elem.description }} className='mr-3'></div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div dangerouslySetInnerHTML={{ __html: elem.description2 }} className='ml-3'></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ThreeColumn