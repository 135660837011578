import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import Fancybox from './FancyBox'

const Faq = ({elem}) => {
  return (
    <div className='faq'>
        <div className="main-container">
            <div className='faq-wrapper'>
                <Fancybox
                    options={{
                        Carousel: {
                        infinite: false,
                        },
                    }}
                >
                    <div className='faq-left'>
                        {elem.video && <a href={elem.video} data-fancybox="gallery" className='play'>
                            <i className='fa fa-play'></i>
                        </a>}
                        {/*<img src={ elem.image} alt="" />*/}
                    </div>
                </Fancybox>
                <div className='faq-content'>
                    <Tab.Container defaultActiveKey="tab-description0">
                        <Nav>
                            {elem.elements?.map((el, index) =>
                                <Nav.Item key={index}>
                                    <Nav.Link eventKey={`tab-description${index}`}>{el.title}</Nav.Link>
                                </Nav.Item>
                            )}
                        </Nav>
                        <Tab.Content>
                            {elem.elements?.map((el, index) =>
                                <Tab.Pane eventKey={`tab-description${index}`} key={index}>
                                    <img className='pane-img' src={"https://solution-0004.panama.kz" + el.image} alt="" />
                                    <div dangerouslySetInnerHTML={{ __html: el.description }}></div>
                                </Tab.Pane>
                            )}
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Faq