import React from 'react'
import { Navigation} from 'swiper';
import {Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import WorkerItem from '../simple/WorkerItem';

const Workers = ({elem}) => {
  return (
    <div className='workers'>
        <div className="main-container">
            <div>
                <h3 className='main-title'>{elem.title}</h3>
            </div>
                <Swiper 
                    modules={[Navigation]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    breakpoints={{
                        480: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 30
                        }
                    }}
                    className='workers-slider'
                >
                    {elem.elements?.map((slide, index) =>
                        <SwiperSlide key={index}> 
                            <WorkerItem slide={slide}/>
                        </SwiperSlide>
                    )}
                </Swiper>
        </div>
    </div>
  )
}

export default Workers