import React from 'react'
import { NavLink } from 'react-router-dom'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import { useState } from 'react'
import { useEffect } from 'react'
import img1 from "../../images/apply.png"
import AppServices from '../../services/general/appServices'
import { useLangContext } from '../../i18n/ProvideLang'
import ApplicationModal from '../modals/ApplicationModal'

const NewsBlog = ({elem}) => {
    const [article, setArticle] = useState();
    const {lang, localizationsItems} = useLangContext();
    const [imgS, setImgS] = useState();
    const [settings, setSettings] = useState();

    const [show, setShow] = useState(false)

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.index)

    const fetchdata = async () => {
        const res = await AppServices.settings();
    
        if(res.statusCode === 200){
            setSettings(res.content)
        }
    }
      
    useEffect(() => {
        fetchdata();
    }, [])

    const { 
        items:links,
    } = useFetchItems(AppServices.menu, "quick-links");

    useEffect(() => {
        if(items){
            setArticle(items.items[0])
        }
    }, [items])

    useEffect(() => {
        if(settings){
            setImgS(settings.find(it => it.type === "quick-img")?.value)
        }
    }, [settings])

  return (
    <div className='new-blog'>
        <div className="main-container">
            <div className="row">
                <div className="col-lg-8 col-md-12">
                    <div className='new-blog-left'>
                        <h3 className="gdlr-core-block-item-title h3_1dd7_10">{elem.title}</h3>
                        <NavLink className="gdlr-core-block-item-read-more" to={`/${lang}/articles`} id="a_1dd7_5">{localizationsItems?.all_news}</NavLink>
                        <div className='news-grid'>
                            <div>
                                {article &&
                                    <NavLink className='news-card' to={`/${lang}/articles/${article.slug}`}>
                                        <img src={ article.preview_image} alt="" className='mb-3'/>
                                        <div>
                                            <div className='news-date'>{article.date}</div>
                                            <h5>{article.title}</h5>
                                        </div>
                                    </NavLink>
                                }
                            </div>
                            <div className='news-sub-grid'>
                                {items?.items?.slice(1, 4).map((card, index) =>
                                    <NavLink to={`/${lang}/articles/${card.slug}`} key={index} className='news-card'>
                                        <img src={ card.preview_image} alt="" />
                                        <div>
                                            <div className='news-date'>{card.date}</div>
                                            <h5>{card.title}</h5>
                                        </div>
                                    </NavLink>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 news-relative">
                    <div className='news-back-img'>
                        <button className='my-button red-button'>
                            <img src={ imgS} alt="" /> 
                            <span>{localizationsItems?.quick_links}</span>
                        </button>
                        <img src={ elem.news_image} alt="" />
                    </div>
                    <div className='quick-links'>
                        {/*<h3>{localizationsItems?.quick_links}</h3>*/}
                        <div className='list'>
                            {links?.map((link, index) =>
                                <NavLink to={link.value} target={link.type} key={index}>{link.title}</NavLink>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*show && <ApplicationModal show={show} setShow={setShow}/>*/}
    </div>
  )
}

export default NewsBlog