import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/articleServices'
import { NavLink } from 'react-router-dom'
import Pagination from './Pagination'
import Utils from '../../services/utils'

const SampleArticleComponent = ({elem}) => {
    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(ArticleServices.sampleIndex, elem.categories)

  return (
    <div className='page-container'>
        <div className="main-container">
            <div className="row">
                {items?.items?.map((card, index) => 
                    <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                        <div className="blog-card">
                            <img src={ card.preview_image} alt="" />
                            <div className='blog-card-content'>
                                <h3>
                                    <NavLink to={`/ru/articles/${card.slug}`}>{card.title}</NavLink>
                                </h3>
                                <div>{Utils.formatDate(new Date(card.date))}</div>
                            </div>
                        </div>
                    </div>
                )}
                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
            </div>
        </div>
    </div>
  )
}

export default SampleArticleComponent