import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import ApplicationModal from '../modals/ApplicationModal';
import { useLangContext } from '../../i18n/ProvideLang';

const About = ({elem}) => {
    const [image, setImage] = useState();
    const [backImage, setBackImage] = useState();
    const {localizationsItems} = useLangContext();

    const [show, setShow] = useState(false)

    useEffect(() => {
        if(elem){
            setImage(elem.elements.find(it => it.type === "images")?.elements[0])
            setBackImage(elem.elements.find(it => it.type === "images")?.elements[1])
        }
    }, [elem])

  return (
    <div className='main-container'>
        <div className="about">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-12 pd-r-0">
                    <img src={ image} alt="" className='about-img-1'/>
                </div>
                <div className="col-lg-6 col-md-6 col-12 about-wrapper">
                    <img src={ backImage} alt="" className='about-img'/>
                    <div className='about-content'>
                        <h3>{elem.title}</h3>
                        <div className='subtitle'>{elem.subtitle}</div>
                        <div className='text' dangerouslySetInnerHTML={{ __html: elem.description }}></div>
                        <button className='my-button' onClick={() => setShow(true)}>{localizationsItems?.button_text}</button>
                    </div>
                </div>
            </div>
        </div>
        {show && <ApplicationModal show={show} setShow={setShow}/>}
    </div>
  )
}

export default About