import React from 'react'
import { Navigation, Autoplay} from 'swiper';
import {Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { useState } from 'react';
import ApplicationModal2 from '../modals/ApplicationModal2';

const OpeningSlider = ({elem}) => {
    const [show, setShow] = useState(false)

  return (
    <>
    <Swiper 
        modules={[Autoplay, Navigation]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        className='main-slider'
        speed={1000}
        loop={true}
        autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
    >
        {elem.elements?.map((slide, index) =>
            <SwiperSlide className='main-slider-item' key={index}> 
                <img src={ slide.front_image} alt=""/>
                <div className="kingster-container">
                    <div className='item-content'>
                        <div className="item-content-subtitle" 
                            style={{color: slide.banner_font_color}} 
                            dangerouslySetInnerHTML={{ __html: slide.description }}
                        ></div>
                        <h1 className="item-content-title" style={{color: slide.banner_font_color}}>{slide.title}</h1>
                        <div onClick={() => setShow(true)} className="item-content-button">{slide.button_text}</div>
                    </div>
                </div>
            </SwiperSlide>
        )}
    </Swiper>
    {show && <ApplicationModal2 show={show} setShow={setShow}/>}
    </>
  )
}

export default OpeningSlider