import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang';

const Breadcrumbs = ({item}) => {
    const {localizationsItems} = useLangContext();

  return (
    <div className='breadcrumbs-wrapper'>
        <div className="main-container">
            <div className='breadcrumbs'>
                <div className='list'>
                    <NavLink to={'/'}>{localizationsItems?.main}</NavLink>
                    <span>&gt;</span>
                    {item?.title}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Breadcrumbs