import React from 'react'

const ChessComponent = ({elem}) => {
  return (
    <div className='chess-component'>
            <div className='chess-grid'>
                {elem?.elements?.map((el, index) =>
                    index%2 === 0 ?
                        <div className="chess-item margin-left" key={index}>
                            <div className='chess-content'>
                                <div className='chess-elem'>
                                    <h3 className='main-title'>{el.title}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: el.description }}></div>
                                </div>
                            </div>
                            <img src={ el.image} alt="" />
                        </div>
                        :
                        <div className="chess-item margin-right" key={index}>
                            <img src={ el.image} alt="" />
                            <div className='chess-content'>
                                <div className='chess-elem'>
                                    <h3 className='main-title'>{el.title}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: el.description }}></div>
                                </div>
                            </div>
                        </div>
                )}
            </div>
    </div>
  )
}

export default ChessComponent