import React, { useState } from 'react'
import CustomCollapse from '../simple/CustomCollapse'

const FaqSample = ({elem}) => {
    const [currentItem, setCurrentItem] = useState();

  return (
    <div className='faq-sample'>
        <div className="main-container">
            <div className='mb-3'>
                {elem.title && <h3 className='main-title'>{elem.title}</h3>}
            </div>
            {elem.elements?.map((el, index) => 
                <CustomCollapse elem={el} key={index} currentItem={currentItem} setCurrentItem={setCurrentItem}/>
            )}
        </div>
    </div>
  )
}

export default FaqSample