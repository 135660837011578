import React, { useEffect, useState } from 'react'
import TopBar from './TopBar'
import AppServices from '../../services/general/appServices';
import useFetchItems from '../../hooks/useFetchItems';
import { NavLink } from 'react-router-dom';
import MobileHeader from './MobileHeader';

const Header = ({item}) => {
    const [logo, setLogo] = useState();

    const [sticky, setStiky] = useState();

    const { 
        items, 
        loading, 
        params, 
        handlePageClick, 
        setFilterData 
    } = useFetchItems(AppServices.menu, "main-menu");

    useEffect(() => {
        if(item){
            setLogo(item.find(it => it.type === "logo")?.value)
        }
    }, [item])

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 100){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

  return (
    <>
        <TopBar item={item}/>
        <header className={sticky ? "kingster-header-wrap kingster-header-style-plain kingster-style-menu-right kingster-sticky-navigation kingster-style-fixed header-sticky" : "kingster-header-wrap kingster-header-style-plain kingster-style-menu-right kingster-sticky-navigation kingster-style-fixed"} data-navigation-offset="75px">
            <div className="kingster-header-background"></div>
            <div className="kingster-header-container  kingster-container">
                <div className="kingster-header-container-inner clearfix">
                    <div className="kingster-logo  kingster-item-pdlr">
                        <div>
                            <NavLink to={'/'}>
                                <img src={ logo} alt="" />
                            </NavLink>
                        </div>
                    </div>
                    <div className="kingster-navigation kingster-item-pdlr clearfix ">
                        <div className="kingster-main-menu" id="kingster-main-menu">
                            <ul id="menu-main-navigation-1" className="sf-menu">
                                {items?.map((it, index) =>
                                    <li className="menu-item menu-item-home menu-item-has-children kingster-normal-menu" key={index}>
                                        <NavLink to={it.value} target={it.type} className="sf-with-ul-pre">{it.title}</NavLink>
                                        {it.items &&
                                            <ul className="sub-menu">
                                                {it.items?.map((el, index) =>
                                                    <li key={index} className="menu-item menu-item-has-children" data-size="60">
                                                        <NavLink to={el.value} target={el.type} className="sf-with-ul-pre">{el.title}</NavLink>
                                                        {el.items &&
                                                            <ul className="sub-menu">
                                                                {el.items?.map((link, index) =>
                                                                    <li className="menu-item menu-item-has-children" key={index}>
                                                                        <NavLink to={link.value} target={link.type} className="sf-with-ul-pre">{link.title}</NavLink>
                                                                        {link.items &&
                                                                            <ul className="sub-menu">
                                                                                {link.item?.map((l, index) =>
                                                                                    <li className="menu-item" key={index}>
                                                                                        <NavLink to={l.value}>{l.title}</NavLink>
                                                                                    </li>
                                                                                )}
                                                                            </ul>
                                                                        }
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        }
                                                    </li>
                                                )}
                                            </ul>
                                        }
                                    </li>
                                )}
                            </ul>
                            <div className="kingster-navigation-slide-bar" id="kingster-navigation-slide-bar"></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <MobileHeader logo={logo} items={items}/>
    </>
  )
}

export default Header