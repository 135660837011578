import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import { NavLink } from 'react-router-dom';

const Advantages2 = ({elem}) => {
    const {localizationsItems} = useLangContext();

  return (
    <div className='advantages-2'>
        <div className="main-container">
            <div className="row">
                {elem.elements?.map((card, index) =>
                    <div className="col-lg-3 col-md-6 advantages2-item" key={index}>
                        <img src={ card.image} alt="" />
                        <h5>{card.title}</h5>
                        <p>{card.subtitle}</p>
                        <NavLink to={card.link}>{localizationsItems?.learn_more}</NavLink>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Advantages2