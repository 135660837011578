import React, { useEffect, useState } from 'react'
import Fancybox from './FancyBox';

const Video = ({elem}) => {  
  return (
    <Fancybox
        options={{
            Carousel: {
            infinite: false,
            },
        }}
    >
        <a href={elem.video} data-fancybox="gallery">
            <div className='video'>
                <div className="main-container">
                    <div className='video-back'>
                        {elem.video_image &&
                            <img src={ elem.video_image} alt="" />
                        }
                    </div>
                    <div className='video-content'>
                        <div className='center'>
                            <i className='fa fa-play-circle'></i>
                            <h3 className="gdlr-core-title-item-title gdlr-core-skin-title " id="h3_1dd7_23">{elem.title}</h3>
                            <p dangerouslySetInnerHTML={{ __html: elem.description }}></p>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </Fancybox>
  )
}

export default Video