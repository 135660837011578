import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import AppServices from '../../services/general/appServices';
import useFetchItems from '../../hooks/useFetchItems';
import { NavLink } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';

const Footer = ({item}) => {
    const [logo, setLogo] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [address, setAddress] = useState();
    const [socials, setSocials] = useState();
    const {localizationsItems} = useLangContext();

    const { 
        items,
    } = useFetchItems(AppServices.menu, "bottom-menu");

    useEffect(() => {
        if(item){
            setLogo(item.find(it => it.type === "white-logo")?.value)
            setEmail(item.find(it => it.type === "email"))
            setAddress(item.find(it => it.type === "address"))
            setPhone(item.find(it => it.type === "phone"))
            setSocials(item.find(it => it.type === "socials")?.value)
        }
    }, [item])

  return (
    <div className='footer'>
        <div className="main-container">
            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12">
                    <div className='footer-left'>
                        <img src={ logo} alt="" />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 footer-item">
                    <h4>{localizationsItems?.contact_us}</h4>
                    <ul>
                        <li>
                            <i className='fa fa-location-arrow'></i>
                            <div>
                                {address?.value.map((em, index) => 
                                    <span key={index}>{em}</span>
                                )}
                            </div>
                        </li>
                        {email?.value.map((em, index) => 
                            <li key={index}>
                                <i className='fa fa-envelope-open-o'></i>
                                <a href={`mailto:${em}`} key={index}>{em}</a>
                            </li>
                        )}
                        {phone?.value.map((phone, index) => 
                            <li key={index}>
                                <i className='fa fa-phone'></i>
                                <span dangerouslySetInnerHTML={{__html: phone}}></span>
                            </li>
                        )}
                    </ul>
                </div>
                {/*<div className="col-lg-3 col-md-4 col-sm-12 footer-item">
                    <h4>{localizationsItems?.hr_department}</h4>
                    <ul>
                        <li>
                            <i className='fa fa-envelope-open-o'></i>
                            <div>
                                {email?.value.map((em, index) => 
                                    <a href={`mailto:${em}`} key={index}>{em}</a>
                                )}
                            </div>
                        </li>
                        <li>
                            <i className='fa fa-phone'></i>
                            <div>
                                {phone?.value.map((phone, index) => 
                                    <a href={`tel:${phone}`} key={index}>{phone}</a>
                                )}
                            </div>
                        </li>
                    </ul>
                </div>*/}
                <div className="col-lg-4 col-md-4 col-sm-12 footer-item">
                    <h4>{localizationsItems?.follow_us}</h4>
                    <ul>
                        {socials?.map((el, index) =>
                            <li key={index}>
                                <NavLink to={el.value} key={index} target='blank'>
                                    <i className={`fa fa-${el.type}`}></i> {el.name}
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
        <div className='footer-bottom'>
            <div className='main-container'>    
                <div className='footer-flex'>
                    <div>
                        © Panama.kz
                    </div>
                </div>               
            </div> 
        </div>
    </div>
  )
}

export default Footer