import React from 'react'
import Fancybox from './FancyBox'
import img from '../../images/placeholder.png'
import { Navigation} from 'swiper';
import {Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const ShortsVideo = ({elem}) => {
  return (
    <div className="main-container steps-2">
        <div className="row">
            <div className="col-12">
                <h3 className='h3_1dd7_10'>{elem?.title}</h3>
            </div>
        </div>
        <Fancybox
                options={{
                    Carousel: {
                    infinite: false,
                    },
                }}
            >
                <Swiper
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={1}
                        navigation
                        breakpoints={{
                            590: {
                                slidesPerView: 2,
                            },
                            812: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            }
                        }}
                        className='shorts-slider'
                    >
                    {elem?.elements?.map((card, index) =>
                        <SwiperSlide key={index}>
                            <a href={card.url} target="_blank" data-fancybox="gallery" className={elem.is_gorizontal ? 'video-img2' : 'video-img'}>
                                <img src={card.image ? card.image : img} alt=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                />
                                <span>
                                    <i className='fa fa-play-circle'></i>
                                </span>
                            </a>
                        </SwiperSlide>
                    )}
                </Swiper>
        </Fancybox>
    </div>
  )
}

export default ShortsVideo