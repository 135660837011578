import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

const AboutUs = ({elem}) => {
    const [image, setImage] = useState();
    const [backImage, setBackImage] = useState();

    useEffect(() => {
        if(elem){
            setImage(elem.elements.find(it => it.type === "images")?.elements[0])
            setBackImage(elem.elements.find(it => it.type === "images")?.elements[1])
        }
    }, [elem])

  return (
    <div className='about-us'>
        <img src={ image} alt="" className='about-us-img'/>
        <div className="main-container">
            <div className="about-us-wrapper">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="about-us-right">
                            <img src={ backImage} alt="" />
                            <h3>{elem.title}</h3>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div dangerouslySetInnerHTML={{ __html: elem.description }}></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutUs