import React from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import PageTop from '../../components/complex/PageTop'
import EventsGrid from '../../components/complex/EventsGrid'
import { useLangContext } from '../../i18n/ProvideLang'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const EventsPage = () => {
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/events`)
            }
        }
    }, [lang])

    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(AppServices.page, 'events')

  return (
    <>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
            :
        <div>
            {items &&
            <Helmet>
                <title>{items.seo_title ? items.seo_title : items.title}</title>
                {items.seo_description && <meta name="description" content={items.seo_description} />}
                {items.seo_keywords && <meta name="Keywords" content={items.seo_keywords}/>}
            </Helmet>}
            <PageTop item={items}/>
            <EventsGrid/>
        </div>
        }
    </>
  )
}

export default EventsPage