import React, { useEffect } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import AppServices from '../../services/general/appServices';
import Utils from '../../services/utils';
import useFetchItems from '../../hooks/useFetchItems';

const ContactForm = ({elem}) => {
    const {localizationsItems} = useLangContext();
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [points, setPoints] = useState();
    const [scText, setScText] = useState();

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const {
        items,
    } = useFetchItems(AppServices.settings)

    useEffect(() => {
        if(items){
            let text = items.find(it => it.type === "b24-sample-form-script")?.value;
            setScText(text)
        }
    }, [items])

    useEffect(() => {
        if(scText){
            const bitForm = document.getElementById('sampleformbitr');
            
            if(!bitForm.hasChildNodes()){
                const divFragment =  document.createRange().createContextualFragment(scText[0])
            
                bitForm.appendChild(divFragment)
            }
        }
    }, [scText])

    const CustomSubmit = async (data) => {
        setLoading(true);

        let newData = {
            fields: {},
            bitrix_uid: elem.bitrix_uid
        }

        for(var key in data){
            let temp = elem.elements.find(it => it.name === key)
            if(temp){
                newData.fields[temp.label] = data[key]
            }
        }

        let query = new URLSearchParams(window.location.search);

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            Utils.getCookie('utm_source') && newData.append('utm_source', Utils.getCookie('utm_source'));
            Utils.getCookie('utm_medium') && newData.append('utm_medium', Utils.getCookie('utm_medium'));
            Utils.getCookie('utm_campaign') && newData.append('utm_campaign', Utils.getCookie('utm_campaign'));
            Utils.getCookie('utm_content') && newData.append('utm_content', Utils.getCookie('utm_content'));
            Utils.getCookie('utm_term') && newData.append('utm_term', Utils.getCookie('utm_term'));
        }

        const res = await AppServices.requestTemplate(newData);

        if(res.statusCode === 200){
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                reset();
            }, 3000);
        }else{
            console.log(res)
        }
        setLoading(false)
    }

  return (
    <div className='contact-form'>
        <div className='form-back'>
            <img src={elem.form_image} alt="" />
        </div>
        <div className="main-container">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12"></div>
                <div className="col-lg-6 col-md-6 col-sm-12 relative">
                    <div className='form-wrapper'>
                        <h3>{elem.title}</h3>
                        {scText ?
                            <div id='sampleformbitr'></div>
                            :
                            <form onSubmit={handleSubmit(CustomSubmit)}>
                                {elem.elements &&
                                    <>
                                        {elem.elements.map((el, index) =>
                                            <label key={index} className='label'>
                                                <input type="text" {...register(el.name)} className='input' name={el.name} placeholder={el.placeholder}/>
                                            </label>
                                        )}
                                        <button className='my-button' type='submit'>{localizationsItems?.submit}</button>
                                    </>
                                }
                            </form>
                        }
                    </div>
                    <div className={visible ? 'form-contact-success form-contact-success-visible' : 'form-contact-success'}>
                        <h3>{localizationsItems?.form_thanks}</h3>
                        <p>{localizationsItems?.form_content}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactForm