import React from 'react';
import useFetchItems from '../../hooks/useFetchItems';
import EventsServices from '../../services/general/eventsServices';
import Utils from '../../services/utils';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';

const EventsGrid = () => {
    const navigate = useNavigate();
    const {lang} = useLangContext();

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(EventsServices.index)

  return (
    <div className='events'>
        <div className="main-container">
            <div className="row">
                {items?.items?.map((card, index) =>
                    <div className="col-lg-4 col-md-6" key={index}>
                        <div className="event-card2">
                            <div className='block'>
                                <NavLink to={`/${lang}/events/${card.slug}`}>
                                    <img src={ card.preview_image} alt="" />
                                </NavLink>
                            </div>
                            <div className='title'>
                                <div className='date'>
                                    <span>{Utils.generateDate2(card.date).day}</span>
                                    <p>{Utils.generateDate2(card.date, lang).mon}</p>
                                </div>
                                <h3>
                                    <NavLink to={`/${lang}/events/${card.slug}`}>{card.title}</NavLink>
                                </h3>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default EventsGrid