import React from 'react'
import Fancybox from './FancyBox'
import { Navigation} from 'swiper';
import {Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

const Gallery = ({elem}) => {
  return (
    <div className='gallery'>
        <div className='main-container'>
            {elem.title && <h3 className='main-title'>{elem.title}</h3>}
        </div>
        {elem?.is_slider ?
            <Fancybox className="project-area bg-secondary"
                options={{
                    Carousel: {
                    infinite: false,
                    },
                }}
            >
                <Swiper 
                    modules={[Navigation]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 4,
                        }
                    }}
                    className='gallery-slider'
                >
                    {elem.elements?.map((slide, index) =>
                        <SwiperSlide key={index}> 
                            <div className="gallery-item gallery-slide">
                                <a href={ slide} data-width="700" data-height="700" className="" target="_blank" data-fancybox="gallery">
                                    <img className="" src={ slide} alt=""/>
                                </a>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </Fancybox>
        :
            <Fancybox className="project-area bg-secondary"
                options={{
                    Carousel: {
                    infinite: false,
                    },
                }}
            >
                <div className="gallery-grid">
                        {elem.elements?.map((card, index) =>
                            <a data-fancybox="gallery" href={ card} key={index}>
                                <div className="gallery-item">
                                    <div className="gallery-image">
                                        <img src={ card} alt="" />
                                        <span>
                                            <i className='fa fa-search'></i>
                                        </span>
                                    </div>
                                </div>
                            </a>
                        )}
                </div>
            </Fancybox>
        }
    </div>
  )
}

export default Gallery