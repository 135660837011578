import React from 'react'
import AppServices from '../../services/general/appServices'
import useFetchItems from '../../hooks/useFetchItems';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/simple/Breadcrumbs';
import Advantages from '../../components/complex/Advantages';
import About from '../../components/complex/About';
import AboutUs from '../../components/complex/AboutUs';
import Advantages2 from '../../components/complex/Advantages2';
import NewsBlog from '../../components/complex/NewsBlog';
import Video from '../../components/complex/Video';
import Steps from '../../components/complex/Steps';
import EventsBlock from '../../components/complex/EventsBlock';
import ContactForm from '../../components/complex/ContactForm';
import ChessComponent from '../../components/complex/ChessComponent';
import ThreeColumn from '../../components/complex/ThreeColumn';
import Gallery from '../../components/complex/Gallery';
import TextComponent from '../../components/complex/TextComponent';
import Workers from '../../components/complex/Workers';
import FaqSample from '../../components/complex/FaqSample';
import { useLangContext } from '../../i18n/ProvideLang';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageTop from '../../components/complex/PageTop';
import SampleArticleComponent from '../../components/complex/SampleArticleComponent';
import { useState } from 'react';

const SamplePage = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const{lang: lang2} = useParams();
    const [items, setItems] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(lang){
            if(lang !== lang2){
                navigate(`/${lang}/page/${slug}`)
            }
        }
    }, [lang])

    const fetchdata = async () => {
        setLoading(true)
        const res = await AppServices.page(slug);
    
        if(res.statusCode === 200){
            setItems(res.content)
        }
        setLoading(false)
    }
    
    useEffect(() => {
        fetchdata();
    }, [slug, lang])

    useEffect(() => {
        if(items?.description){
            const o = new DOMParser().parseFromString(items.description, "text/html")
            const s = [...o.querySelectorAll('script')].map(o => o.outerHTML).join('\n')
            if(s){
                const divFragment =  document.createRange().createContextualFragment(s)
                document.body.prepend(divFragment);
            }
        }
    }, [items])

  return (
    <>
    {loading ?
        <div className="preloader">
            <div className="isLoading"></div>
        </div>
        :
        <div className='sample-page'>
            {items &&
                <Helmet>
                    <title>{items.seo_title ? items.seo_title : items.title}</title>
                    {items.seo_description && <meta name="description" content={items.seo_description} />}
                    {items.seo_keywords && <meta name="Keywords" content={items.seo_keywords}/>}
                </Helmet>
            }
            <PageTop item={items}/>
            <Breadcrumbs item={items}/>
            {items?.description && <div className="main-container">
                <div className='description' dangerouslySetInnerHTML={{ __html: items.description }}></div>
            </div>}
            {items &&
                items.elements?.map((elem, index) =>
                    <div key={index}>
                        {
                            elem.type === "advantages" ?
                                <Advantages elem={elem}/>
                            :
                            elem.type === "about" ?
                                <About elem={elem}/>
                            :
                            elem.type === "about-2" ?
                                <AboutUs elem={elem}/>
                            :
                            elem.type === "advantages-2" ?
                                <Advantages2 elem={elem}/>
                            :
                            elem.type === "articles" ?
                                <SampleArticleComponent elem={elem}/>
                            :
                            elem.type === "video-component" ?
                                <Video elem={elem}/>
                            :
                            elem.type === "faq" ?
                                <FaqSample elem={elem}/>
                            :
                            elem.type === "steps" ?
                                <Steps elem={elem}/>
                            :
                            elem.type === "events-2" ?
                                <EventsBlock elem={elem}/>
                            :
                            elem.type === "form" ?
                                <ContactForm elem={elem}/>
                            :
                            elem.type === "chess" ?
                                <ChessComponent elem={elem}/>
                            :
                            elem.type === "text-component-title" ?
                                <ThreeColumn elem={elem}/>
                            :
                            elem.type === "gallery" ?
                                <Gallery elem={elem}/>
                            :
                            elem.type === "text-information" ?
                                <TextComponent elem={elem}/>
                            :
                            elem.type === "workers" ?
                                <Workers elem={elem}/>
                            :
                            <></>
                        }
                    </div>    
                )
            }
        </div>
    }
    </>
  )
}

export default SamplePage