import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ArticleServices from '../../services/general/articleServices';
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import ArticleContainer from '../../components/complex/ArticleContainer';
import ArticleTop from '../../components/complex/ArticleTop';
import Breadcrumbs from '../../components/simple/Breadcrumbs';
import { useLangContext } from '../../i18n/ProvideLang';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Gallery from '../../components/complex/Gallery';

const ArticleDetail = () => {
    const {slug} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/articles/${slug}`)
            }
        }
    }, [lang])

    const {info, loading} = useFetchInfo(ArticleServices.view, slug, null, lang);

  return (
    <>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
            :
        <div>
            {info &&
            <Helmet>
                <title>{info.seo_title ? info.seo_title : info.title}</title>
                {info.seo_description && <meta name="description" content={info.seo_description} />}
                {info.seo_keywords && <meta name="Keywords" content={info.seo_keywords}/>}
            </Helmet>}
            <ArticleTop item={info}/>
            <Breadcrumbs item={info}/>
            <ArticleContainer item={info}/>
        </div>
        }
    </>
  )
}

export default ArticleDetail