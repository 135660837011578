import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang'
import { NavLink } from 'react-router-dom';

const Error = () => {
    const {localizationsItems, lang} = useLangContext();

  return (
    <div className="kingster-page-wrapper" id="kingster-page-wrapper">
        <div className="kingster-not-found-wrap" id="kingster-full-no-header-wrap">
            <div className="kingster-not-found-background"></div>
            <div className="kingster-not-found-container kingster-container">
                <div className="kingster-header-transparent-substitute"></div>
                <div className="kingster-not-found-content kingster-item-pdlr">
                    <h1 className="kingster-not-found-head">404</h1>
                    <h3 className="kingster-not-found-title kingster-content-font">{localizationsItems?.not_found}</h3>
                    <div className="kingster-not-found-caption">{localizationsItems?.error_text}</div>
                    <div className="kingster-not-found-back-to-home"><NavLink to={`/${lang}`}>{localizationsItems?.to_home}</NavLink></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Error